import { AppProps } from 'next/app';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useVariable, withDVCProvider } from '@devcycle/devcycle-react-sdk';
import { initializeApp } from 'firebase/app';

import { AuthProvider } from '@/contexts/AuthContext';
import Container from '@/containers/Container';
import { SocketProvider } from '@/contexts/SocketContext';
import '@/styles/_global.scss';
import { StoreProvider } from '@/contexts/StoreContext';
import { IFoodProvider } from '@/contexts/iFoodContext';
import { FirebaseProvider } from '@/contexts/FirestoreContext';
import { TotemCartProvider } from '../contexts/TotemCartContext';
import '../../i18n';
import { ComponentState } from 'react';
import { CashRegisterProvider } from '../contexts/CashRegisterContext';
import 'react-h5-audio-player/src/styles.scss';
import { AlertProvider } from '@/providers/AlertProvider';
import { ThemeProvider } from '@/assets/theme';
import { PdvsProvider } from '../contexts/PdvsContext';
import { GoalProvider } from '../contexts/GoalContext';
import { StatusPageProvider } from '@/contexts/StatusPageContext';
import { Realtime } from 'ably';
import { AblyProvider } from 'ably/react';
import { AblyEventsProvider } from '@/providers/AblyEventsProvider';
const queryClient = new QueryClient();
const routesWithoutAuth = ['/login', '/404'];

const { publicRuntimeConfig } = getConfig();

function App({ Component, pageProps }: AppProps) {
  const route = useRouter();

  initializeApp({
    apiKey: publicRuntimeConfig.FIREBASE_API_KEY,
    authDomain: publicRuntimeConfig.FIREBASE_AUTH_DOMAIN,
    databaseURL: publicRuntimeConfig.FIREBASE_DATABASE_URL,
    projectId: publicRuntimeConfig.FIREBASE_PROJECT_ID,
    storageBucket: publicRuntimeConfig.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: publicRuntimeConfig.FIREBASE_MESSAGING_ID,
    appId: publicRuntimeConfig.FIREBASE_APP_ID,
    measurementId: publicRuntimeConfig.FIREBASE_MEASUREMENT_ID,
  });

  const { value: isAbly } = useVariable('ably', false);
  const client = isAbly
    ? new Realtime.Promise({
        key: publicRuntimeConfig.ABLY_TOKEN,
        useTokenAuth: true,
      })
    : undefined;

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <Head>
          <title>KDS Mais1</title>
        </Head>
        <StatusPageProvider>
          {routesWithoutAuth.includes(route.pathname) ? (
            <Component {...pageProps} />
          ) : (
            <AuthProvider>
              <CashRegisterProvider>
                <StoreProvider>
                  <TotemCartProvider>
                    {isAbly ? (
                      <AblyProvider client={client}>
                        <AblyEventsProvider>
                          <GoalProvider>
                            <IFoodProvider>
                              <FirebaseProvider>
                                <PdvsProvider>
                                  <AlertProvider>
                                    <Container>
                                      <Component {...pageProps} />
                                    </Container>
                                  </AlertProvider>
                                </PdvsProvider>
                              </FirebaseProvider>
                            </IFoodProvider>
                          </GoalProvider>
                        </AblyEventsProvider>
                      </AblyProvider>
                    ) : (
                      <SocketProvider>
                        <GoalProvider>
                          <IFoodProvider>
                            <FirebaseProvider>
                              <PdvsProvider>
                                <AlertProvider>
                                  <Container>
                                    <Component {...pageProps} />
                                  </Container>
                                </AlertProvider>
                              </PdvsProvider>
                            </FirebaseProvider>
                          </IFoodProvider>
                        </GoalProvider>
                      </SocketProvider>
                    )}
                  </TotemCartProvider>
                </StoreProvider>
              </CashRegisterProvider>
            </AuthProvider>
          )}
        </StatusPageProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default withDVCProvider({
  envKey: publicRuntimeConfig.DEVCYCLE_KEY,
  user: {
    user_id: String('default'),
    isAnonymous: false,
  },
})(App as ComponentState);
