/* eslint-disable max-len */

type TranslationKeys =
  | 'opening.machine.espresso'
  | 'opening.machine.coffee'
  | 'opening.oven'
  | 'opening.grinder.refill'
  | 'opening.supplies.organize'
  | 'opening.whipped.cream.check'
  | 'opening.pastel.belem'
  | 'opening.donuts.check'
  | 'opening.food.bake'
  | 'opening.cash.register'
  | 'opening.espresso.adjust'
  | 'opening.extraction.sheet'
  | 'opening.coffee.ready'
  | 'opening.ice.machine'
  | 'opening.ambient.sound'
  | 'opening.ice.portion'
  | 'opening.baked.goods'
  | 'opening.monitor'
  | 'opening.lights'
  | 'opening.stone.device'
  | 'closing.cash.register'
  | 'closing.grinder.off'
  | 'closing.expurge.coffee'
  | 'closing.clean.tamper'
  | 'closing.wash.cloths'
  | 'closing.remove.filters'
  | 'closing.wash.filters'
  | 'closing.clean.espresso.machine'
  | 'closing.wash.grill'
  | 'closing.espresso.off'
  | 'closing.clean.mixer'
  | 'closing.ice.expurge'
  | 'closing.wash.jugs'
  | 'closing.coffee.machine.off'
  | 'closing.oven.off'
  | 'closing.clean.counter'
  | 'closing.defrost.croissant'
  | 'closing.defrost.pastel.belem'
  | 'closing.ice.machine'
  | 'closing.monitor.off'
  | 'closing.ac.off'
  | 'closing.clean.store'
  | 'closing.remove.trash'
  | 'closing.lights.off';

interface CheckItem {
  value: number;
  label: TranslationKeys;
}

export const storeOpeningCheck = {
  opening: [
    { value: 1, label: 'opening.machine.espresso' },
    { value: 2, label: 'opening.machine.coffee' },
    { value: 3, label: 'opening.oven' },
    { value: 4, label: 'opening.grinder.refill' },
    { value: 5, label: 'opening.supplies.organize' },
    { value: 6, label: 'opening.whipped.cream.check' },
    { value: 7, label: 'opening.pastel.belem' },
    { value: 8, label: 'opening.donuts.check' },
    { value: 9, label: 'opening.food.bake' },
    { value: 10, label: 'opening.cash.register' },
    { value: 11, label: 'opening.espresso.adjust' },
    { value: 12, label: 'opening.extraction.sheet' },
    { value: 13, label: 'opening.coffee.ready' },
    { value: 14, label: 'opening.ice.machine' },
    { value: 15, label: 'opening.ambient.sound' },
    { value: 16, label: 'opening.ice.portion' },
    { value: 17, label: 'opening.baked.goods' },
    { value: 18, label: 'opening.monitor' },
    { value: 19, label: 'opening.lights' },
    { value: 20, label: 'opening.stone.device' },
  ] as CheckItem[],
};

export const closingCheck = {
  closing: [
    { value: 1, label: 'closing.cash.register' },
    { value: 2, label: 'closing.grinder.off' },
    { value: 3, label: 'closing.expurge.coffee' },
    { value: 4, label: 'closing.clean.tamper' },
    { value: 5, label: 'closing.wash.cloths' },
    { value: 6, label: 'closing.remove.filters' },
    { value: 7, label: 'closing.wash.filters' },
    { value: 8, label: 'closing.clean.espresso.machine' },
    { value: 9, label: 'closing.wash.grill' },
    { value: 10, label: 'closing.espresso.off' },
    { value: 11, label: 'closing.clean.mixer' },
    { value: 12, label: 'closing.ice.expurge' },
    { value: 13, label: 'closing.wash.jugs' },
    { value: 14, label: 'closing.coffee.machine.off' },
    { value: 15, label: 'closing.oven.off' },
    { value: 16, label: 'closing.clean.counter' },
    { value: 17, label: 'closing.defrost.croissant' },
    { value: 18, label: 'closing.defrost.pastel.belem' },
    { value: 19, label: 'closing.ice.machine' },
    { value: 20, label: 'closing.monitor.off' },
    { value: 21, label: 'closing.ac.off' },
    { value: 22, label: 'closing.clean.store' },
    { value: 23, label: 'closing.remove.trash' },
    { value: 24, label: 'closing.lights.off' },
  ] as CheckItem[],
};
